



























import vue from 'vue';
import CommunityApi from '@/apis/community.api';
import settings from '@/settings';
import {applyLuminanceFilter} from '@/helpers/color-utilities';

export default vue.extend({
    name: 'SideMenu',
    methods: {
        closeSideMenu() {
            this.$emit('close');
        },
        applyBackgroundFilter(backgroundColor: string|null = null): void {
            applyLuminanceFilter(
                this.$refs.forceVisible as HTMLElement,
                backgroundColor ?? this.getSideMenuBackgroundColor(),
            );
        },
        getSideMenuBackgroundColor(): string {
            if (this.$store.state.styling?.web === undefined) return '#87878787';
            return this.$store.state.styling.web['sidemenu_background-color'] || '#87878787';
        },
    },
    computed: {
        logo() {
            return this.$store.state.styling?.community?.sideLogo
            || this.$store.state.styling?.community?.logo
            || 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        },
        style(): string {
            return this.getSideMenuBackgroundColor();
        },
    },
    watch: {
        style(backgroundColor: string): void {
            this.applyBackgroundFilter(backgroundColor);
        },
    },
    data() {
        return {
            sidemenuItems: [] as Record<string, string>[],
            version: settings.version,
        };
    },
    created() {
        CommunityApi
            .getSidemenu(this.$route.params.community)
            .then((data) => {
                this.sidemenuItems = data;
            });
    },
    mounted() {
        this.applyBackgroundFilter();
    },
});
